<template>
  <div class="login-input">
    <img
      v-if="icon"
      :src="icon"
      :style="{
        width: `${size.width / 19.2}vw`,
        height: `${size.height / 19.2}vw`,
      }"
    />
    <input
      :type="type"
      :placeholder="placeholder"
      v-model.trim="value"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },

    size: {
      type: Object,
      default: () => {
        return {
          width: 30,
          height: 30,
        };
      },
    },

    type: {
      type: String,
      default: "text",
    },

    placeholder: {
      type: String,
    },
  },

  data: function () {
    return {
      value: "",
    };
  },

  methods: {
    onInput: function () {
      this.$emit("onInput", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>

.login-input {
  height: 60px;
  background-color: #f5f5f5;
  border-radius: 42px;
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    padding-right: 9px;
  }

  input {
    color: black;
    background-color: #f5f5f5;
    font-size: 16px;
    width: 100%;
  }

  input::-webkit-input-placeholder {
    color: #999999;
    font-size: 16px;
  }
}
</style>