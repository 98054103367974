<template>
  <div class="verify-code">
    <login-input class="verify-code-input" :icon="phoneIcon" :size="{ width: 20, height: 28 }" placeholder="手机号"
      @onInput="onPhoneNumberInput" />
    <div class="verify-code-top">
      <login-input class="verify-code-top-input" :icon="msgIcon" placeholder="验证码" @onInput="onVerifyCodeInput" />
      <button class="verify-code-top-button" :class="{ disable: isCountingDown }" @click="onSendCodeClick">
        {{ codeButtonTitle }}
      </button>
    </div>
    <button class="verify-code-switch" @click="onSwithClick">
      使用密码登录
    </button>
    <button class="verify-code-login" @click="onLogin">登 录</button>
  </div>
</template>

<script>
import LoginInput from "@c/login/login-input.vue";
import { CacheTool } from "@js/cache-tool.js";
import { ValidateTool } from "@js/validate-tool.js";
import { countDown } from "@js/common-tool.js";
export default {
  components: {
    LoginInput,
  },

  data() {
    return {
      phoneIcon: require("@imgs/login-mobile.png"),
      msgIcon: require("@imgs/login-code.png"),
      phoneNumber: "",
      verifyCode: "",
      countDownNumber: 0,
      currentCountDownNumber: 0,
    };
  },

  computed: {
    codeButtonTitle() {
      return this.countDownNumber === 0
        ? "获取验证码"
        : this.countDownNumber + "s";
    },

    isCountingDown() {
      return this.countDownNumber != 0;
    },
  },

  methods: {
    onSwithClick() {
      this.$emit("onSwithClick", "VerifyCode");
    },

    onPhoneNumberInput(value) {
      this.phoneNumber = value;
    },

    onVerifyCodeInput(value) {
      this.verifyCode = value;
    },

    onSendCodeClick() {
      if (this.isCountingDown === true) {
        return;
      }
      if (
        this.phoneNumber === "" ||
        !ValidateTool.isPhoneNumber(this.phoneNumber)
      ) {
        this.$toast.showRed("手机号格式有误！");
        return;
      }
      this.sendVerifyCode();
    },

    async sendVerifyCode() {
      try {
        const params = {
          toPhone: this.phoneNumber,
          smsType: 15,
        }
        await this.$api.sendVerifyCode(params);
        this.$toast.showGreen("验证码发送成功");
        this.countDown();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    countDown() {
      this.countDownNumber = window.MSG_LIMIT_SECOND;
      countDown({
        remainSeconds: window.MSG_LIMIT_SECOND,
        trigger: (remainSeconds) => {
          this.countDownNumber = remainSeconds;
        },
        over: () => {
          this.countDownNumber = 0;
        },
      });
    },

    onLogin() {
      if (this.phoneNumber === "" || this.verifyCode === "") {
        this.$toast.showRed("手机号或验证码错误!");
        return;
      }
      this.login();
    },

    async login() {
      try {
        const params = {
          phone: this.phoneNumber,
          verificationCode: this.verifyCode,
        }
        const data = await this.$api.loginByVerifyCode(params);
        CacheTool.saveLoginInfo(data);
        this.$emit("onLoginSucceed");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-input {
    width: 100%;
  }

  &-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 27px;

    &-input {
      width: 192px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &-button {
      color: black;
      background-color: #f5f5f5;
      font-size: 16px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      width: 132px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .disable {
      opacity: 0.65;
    }
  }

  &-switch {
    color: #2e6be6;
    font-size: 16px;
    align-self: flex-start;
    margin-top: 27px;
  }

  &-login {
    color: white;
    background-color: #4382fc;
    font-size: 24px;
    border-radius: 42px;
    width: 100%;
    height: 60px;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>