<template>
  <div class="login">
    <img class="login-background" src="@imgs/login-background.png" />
    <div class="login-container">
      <div class="login-container-content">
        <div class="login-container-content-top">
          <img class="login-container-content-top-logo" src="@imgs/login-bajie.png" />
          <div class="login-container-content-top-right">
            <p class="login-container-content-top-right-title">
              NPPV辅助管理系统
            </p>
            <div class="login-container-content-top-right-bar"></div>
          </div>
        </div>
        <component class="login-container-content-middle" :is="currentComponent" @onSwithClick="onSwithClick"
          @onLoginSucceed="onLoginSucceed"></component>
      </div>
      <img class="login-container-slogan" src="@imgs/login-info.png" />
      <p class="login-container-icp">浙ICP备14025672号-1</p>
    </div>
  </div>
</template>

<script>
import Password from "@c/login/password.vue";
import VerifyCode from "@c/login/verify-code.vue";

export default {
  components: {
    Password,
    VerifyCode,
  },

  data: () => {
    return {
      currentComponent: "Password",
    };
  },
  methods: {
    onSwithClick(component) {
      if (component === "VerifyCode") {
        this.currentComponent = "Password";
      } else {
        this.currentComponent = "VerifyCode";
      }
    },

    async onLoginSucceed() {
      this.$toast.showGreen("登录成功")
      await this.getAdminInfo();
    },

    async getAdminInfo() {
      try {
        const data = await this.$api.getAdminInfo({});
        this.$store.commit("saveAccountInfo", data);
        this.$router.push("/");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  &-background {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &-container {
    position: absolute;
    right: 129px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-content {
      background-color: white;
      border-radius: 39px;
      width: 563px;
      height: 616px;
      margin-top: 112px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-top {
        margin-top: 87px;
        display: flex;

        &-logo {
          width: 71px;
          height: 71px;
        }

        &-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin-left: 6px;

          &-title {
            color: #666666;
            font-size: 28px;
            font-weight: bold;
            line-height: 37px;
            margin-top: 18px;
          }

          &-bar {
            background-color: #4382fc;
            width: 33px;
            height: 6px;
            border-radius: 3px;
          }
        }
      }

      &-middle {
        width: 336px;
        margin-top: 35px;
      }
    }

    &-slogan {
      width: 450px;
      margin-top: 56px;
    }

    &-icp {
      color: #f5f5f5;
      font-size: 16px;
    }
  }
}
</style>