<template>
  <div class="password">
    <login-input class="password-input" :icon="accountIcon" placeholder="账号" @onInput="onAccountInput" />
    <login-input class="password-input bottom" type="password" :icon="passwordIcon" placeholder="密码"
      @onInput="onPasswordInput" />
    <div class="password-middle">
      <button class="password-middle-button" @click="onSwithClick">
        使用手机验证码登录
      </button>
    </div>
    <button class="password-login" @click="onLogin">登 录</button>
  </div>
</template>

<script>
import LoginInput from "@c/login/login-input.vue";
import { CacheTool } from "@js/cache-tool.js";
import { encrypt } from "@js/aes-tool.js";
import { ValidateTool } from "@js/validate-tool.js";
export default {
  components: {
    LoginInput,
  },

  data() {
    return {
      accountIcon: require("@imgs/login-account.png"),
      passwordIcon: require("@imgs/login-password.png"),
      account: "",
      password: "",
    };
  },
  methods: {
    onSwithClick() {
      this.$emit("onSwithClick", "Password");
    },

    onAccountInput(value) {
      this.account = value;
    },

    onPasswordInput(value) {
      this.password = value;
    },

    onLogin() {
      if (this.account === "" || this.password === "") {
        this.$toast.showRed("账号或密码错误!");
        return;
      }
      this.login();
    },

    async login() {
      try {
        const params = {
          mobile: this.account,
          passWord: encrypt(this.password),
        }
        const data = await this.$api.loginByPassword(params);
        CacheTool.saveLoginInfo(data);
        const isStrongPassword = ValidateTool.isStrongPassword(this.password)
        CacheTool.saveIsStrongPassword(isStrongPassword)
        this.$emit("onLoginSucceed");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-input {
    width: 100%;
  }

  .bottom {
    margin-top: 28px;
  }

  &-middle {
    width: 100%;
    margin-top: 27px;
    display: flex;
    justify-content: space-between;

    &-button {
      color: #2e6be6;
      font-size: 16px;
    }
  }

  &-login {
    color: white;
    background-color: #4382fc;
    font-size: 24px;
    border-radius: 42px;
    width: 100%;
    height: 60px;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>